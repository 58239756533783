<template>
  <div class="app-power">
    <el-button :loading="saving" type="primary" size="mini" @click="saveAppPower">保存</el-button>
    <div class="app-list">
      <el-checkbox-group v-model="powerIds">
        <el-checkbox v-for="app in appList" :key="app.id" :label="app.id.toString()">{{
          app.appName
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-power",
  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      appList: [],
      powerIds: [],
    };
  },
  methods: {
    async refreshRolePower(roleId) {
      let data = await this.$http.doApi('fw-cms-role-power-getRolePower', {
        roleId,
        powerType: 2
      });
      this.powerIds = data.rows;
    },
    async loadAppList() {
      let data = await this.$http.doApi("fw-wx-app-list");
      this.appList = data.rows;
    },
    async saveAppPower() {
      try {
        this.saving = true;
        await this.$http.doApi('fw-cms-role-power-setRolePower', {
          roleId: this.roleId,
          powerType: 2
        }, this.powerIds);
        this.$message.success('保存成功');
      } finally{
        this.saving = false;
      }
    }
  },
  created() {
    this.loadAppList();
  },
  watch: {
    roleId: {
      immediate: true,
      handler(val) {
        this.refreshRolePower(val);
      },
    },
  },
};
</script>

<style lang="less">
.app-power {
  .app-list {
    margin-top: 1rem;
  }
}
</style>